.app {
  @media screen {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto 1fr;
    min-height: 100vh;
  }
}

.content {
  @media screen {
    display: flex;
    justify-content: space-between;
  }
}

@media print {
  .app-bar, .aside-bar {
    display: none !important;
  }
}