@page {
  margin: 0;
}

.print-area {
  position: relative;
  flex-grow: 1;
}

.page-meta {
  position: relative;
  width: 210mm;
  margin: 1cm;
  padding: .5cm 2cm;
  box-sizing: border-box;

  @media print {
    display: none;
  }
}

.page-meta-button {
  @media print {
    display: none;
  }
}

.paper {
  page-break-before: auto;
  page-break-after: auto;
  page-break-inside: avoid;
  box-sizing: border-box;

  @media screen {
    margin: 1cm;
  }

  @media print {
    // width: auto !important;
    // height: auto !important;
    // padding: 0 !important;
    padding-bottom: 0 !important;
    box-shadow: none !important;
  }
}

.paper-inner {
  position: relative;
  page-break-before: auto;
  page-break-after: auto;
  page-break-inside: avoid;

  &.outlined {
    @media screen {
      outline: 1px dashed lightgrey;
    }
  }
}

.print-outer {
  display: flex;
  page-break-before: auto;
  page-break-after: auto;
  page-break-inside: avoid;
  // outline: 1px solid red;
}

.print-inner {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  position: relative;
  page-break-before: auto;
  page-break-after: auto;
  page-break-inside: avoid;
  // outline: 1px solid purple;

  @media print {
    // margin: 0 !important;
  }
}

.border-indicator {
  position: absolute;
  border-top: 1px solid lightgray;
  color: gray;
  font-size: 10px;
  text-align: center;

  &:after {
    position: absolute;
    content: "";
    width: 5px;
    height: 5px;
    border-color: lightgray;
  }

  @media print {
    display: none !important;
  }
}

.border-top-left-indicator {
  transform: rotate(-90deg);
  transform-origin: 100% 0;

  &:after {
    left: -.1mm;
    top: 0;
    transform: rotate(-45deg);
    transform-origin: 0 0;
    border-width: 1px 0 0 1px;
    border-style: solid none none solid;
  }
}

.border-top-right-indicator {
  top: 0;
  transform: rotate(-90deg);
  transform-origin: 100% 0;

  &:after {
    left: -.1mm;
    top: 0;
    transform: rotate(-45deg);
    transform-origin: 0 0;
    border-width: 1px 0 0 1px;
    border-style: solid none none solid;
  }
}

.border-left-top-indicator {
  left: 0;

  &:after {
    right: -.1mm;
    top: 0;
    transform: rotate(45deg);
    transform-origin: 100% 0;
    border-width: 1px 1px 0 0;
    border-style: solid solid none none;
  }
}

.border-right-top-indicator {
  right: 0;

  &:after {
    left: -.1mm;
    top: 0;
    transform: rotate(-45deg);
    transform-origin: 0 0;
    border-width: 1px 0 0 1px;
    border-style: solid none none solid;
  }
}

.crop-mark {
  position: absolute;

  &.top, &.bottom {
    border-left: 1px dashed lightblue;
    border-right: 1px dashed lightblue;
  }

  &.top {
    top: 0;
  }

  &.bottom {
    bottom: 0;
  }

  &.left, &.right {
    border-top: 1px dashed lightblue;
    border-bottom: 1px dashed lightblue;
  }

  &.left {
    left: 0;
  }

  &.right {
    right: 0;
  }
}

.tactical-symbol-wrapper {
  page-break-before: avoid;
  page-break-after: avoid;
  page-break-inside: avoid;
}

.tactical-symbol-wrapper-inner {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1px;

  &.outlined {
    border: 1px dashed lightblue;
    padding: 0;
  }

  &.with-subline {
    flex-direction: column;
  }
}

.tactical-symbol-image {
  display: block;
  object-fit: contain;

  @media screen {
    &.outlined {
      outline: 1px dotted rgba(orange, .5);
      outline-offset: -1px;
    }
  }
}

.text-line {
  height: 1cm;
  margin: 0 5mm;
  border-bottom: .25mm dashed lightgrey;
}
