$aside-width: 700px;

.selection-area {
  min-width: $aside-width;
  max-width: $aside-width;
}

$group-title-foreground: white;
$group-title-background: cadetblue;

.group-title{
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: .2rem 1rem;
  font-size: 1.2rem;
  background: $group-title-background;
  color: $group-title-foreground;
}

.group-toggle {
  width: 22px;
  height: 22px;
  line-height: 20px;
  border: none;
  border-radius: 50%;
  background: $group-title-foreground;
  color: $group-title-background;
  cursor: pointer;
  appearance: none;
}

.tactical-symbol-figure {
  margin: 0;
  padding: 0;
}

.tactical-symbol-image {
  width: 100%;
  object-fit: contain;
}
